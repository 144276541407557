<template>
  <div class="page-header clear-filter">
    <img class="squiggle wsl" src="img/squiggle.png"  v-popover:popover-squiggle type="default"/>
    <img class="wiggles wsl" src="img/wiggles.png"  v-popover:popover-wiggles type="default"/>
    <img class="whiskers wsl" src="img/whiskers.png"  v-popover:popover-whiskers type="default"/>
    <img class="snickerdoodle wsl" src="img/snickerdoodle.png"  v-popover:popover-snickerdoodle type="default"/>
    <div
        class="page-header-image"
        style="background-image: url('img/home.png')"
    ></div>
    <div class="content">
      <div class="ws-title">
        Ordinal Weasels Syndicate
      </div>
      <div class="container">
        <div class="col-md-3 ml-auto mr-auto">
          <card type="login" plain>

            <template slot="raw-content">
              <div class="card-footer text-center home">
                <br/>
                <br/>
                <br/>
                <n-button
                    @click.native="story = true"
                    class="btn btn-primary btn-round btn-lg btn-block story"
                >Ordigin</n-button>

                <a
                    href="https://t.me/weaselstoken"
                    class="btn btn-primary btn-round btn-lg btn-block"
                >Telegram</a>
                <a
                    href="https://twitter.com/OrdinalWeasels" target="_blank"
                    class="btn btn-primary btn-round btn-lg btn-block"
                >Twitter</a>
                <a
                    href="https://magiceden.io/ordinals/marketplace/ows" target="_blank"
                    class="btn btn-primary btn-round btn-lg btn-block"
                >Magic Eden</a>
                <a
                    href="https://www.okx.com/web3/marketplace/nft/collection/btc/ordinal-weasels" target="_blank"
                    class="btn btn-primary btn-round btn-lg btn-block"
                >OKX</a>
                <a
                    href="#pablo"
                    class="btn btn-primary btn-round btn-lg btn-block disabled"
                >WSL</a>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
    <main-footer></main-footer>

    <div class="music-player">
      <audio
          ref="audio"
          src="music/bg.mp3"
          preload
          loop
          id="audio"
          autoplay
          muted
      >
      </audio>
      <div @click="toggleSound()" class="toggle-sound paused">
        <i class="now-ui-icons media-1_button-pause"></i>
        <i class="now-ui-icons media-1_button-play"></i>
      </div>
    </div>
    <el-popover
        ref="popover-squiggle"
        popper-class="popover popover-primary wsl-pop"
        placement="left"
        width="200"
        trigger="click"
    >
      <h3 class="popover-header">Squiggle</h3>
      <div class="popover-body">
        Squiggle is a wily and adventurous weasel known for its unpredictable nature. With its sleek and agile movements, Squiggle can navigate even the trickiest of obstacles, leaving a trail of laughter and awe in its wake.
      </div>
    </el-popover>
    <el-popover
        ref="popover-wiggles"
        popper-class="popover popover-primary wsl-pop"
        placement="left"
        width="200"
        trigger="click"
    >
      <h3 class="popover-header">Wiggles</h3>
      <div class="popover-body">
        Wiggles is a mischievous and energetic little weasel who loves to wiggle and squirm in the most unexpected places. With a knack for getting into hilarious predicaments, Wiggles is always ready to bring a smile to your face.
      </div>
    </el-popover>
    <el-popover
        ref="popover-snickerdoodle"
        popper-class="popover popover-primary wsl-pop"
        placement="left"
        width="200"
        trigger="click"
    >
      <h3 class="popover-header">Snickerdoodle</h3>
      <div class="popover-body">
        Snickerdoodle is a sweet and charming little weasel who brings joy with its playful antics. With a mischievous twinkle in its eye, Snickerdoodle loves to entertain with its silly dances and contagious laughter.
      </div>
    </el-popover>
    <el-popover
        ref="popover-whiskers"
        popper-class="popover popover-primary wsl-pop"
        placement="left"
        width="200"
        trigger="click"
    >
      <h3 class="popover-header">Whiskers</h3>
      <div class="popover-body">
        Whiskers is a curious and intelligent weasel with an insatiable appetite for exploration. With its twitching whiskers and sharp senses, Whiskers is always on the lookout for new adventures, making it the perfect companion for laughter-filled escapades.
      </div>
    </el-popover>
    <modal :show.sync="story" modalClasses="wsl-modal justify-content-center">
      <h4 slot="header" class="title title-up">The Ordigins of the Ordinal Weasels</h4>
      <p>
        Once upon a time, in a world filled with animals of all shapes and sizes, there existed a secret society of weasels who believed that their kind was superior to all others. These weasels, known as the Ordinal Weasels Syndicate, had formed a hierarchy based on the ordinal numbers, with the first weasel at the top and the rest following in rank.
        The Ordinal Weasels had a singular purpose: to rule the world. They believed that they had been chosen by a higher power to achieve this goal and had been working towards it for centuries, manipulating events and pulling strings behind the scenes
      </p>


      <h5>The Den of Iniquity</h5>

      <p>
        The Den of Iniquity was considered the heart of the Ordinal Weasel Syndicate, serving as the headquarters and center of their operations. The towering structure was known for its intimidating presence and the air of mystery that surrounded it. Only members of the syndicate were allowed to enter, and those who did were privy to the secrets and treasures that lay within its walls.
        The Den of Iniquity was heavily guarded, with the most advanced security measures in place to protect its contents and its members. Only those who had earned the trust of the syndicate were allowed to enter, and even then, access was granted only on a need-to-know basis.
        Now, as a NFT collection, the Den of Iniquity holds even greater significance. Access to the collection is based on special merits, and those who are granted entry will be privy to alpha and whitelist access to other collections in the NFT space.
      </p>

      <p>The Ordinal Weasels Syndicate has always been intrigued by the concept of decentralized currencies, especially Bitcoin. They saw it as a way to increase their power and influence beyond their own animal kingdom and expand their reach across the world.</p>

      <p>To achieve their goals, each weasel was given a specific task based on their rank within the hierarchy. The first weasel, being the leader, was tasked with creating a strategy for acquiring Bitcoin, while the second weasel was responsible for identifying potential sources of Bitcoin. This hierarchy allowed for efficient execution of their plan and ensured that all members were working towards a common goal.</p>

      <p>Over the years, the syndicate's wealth had grown exponentially through their meticulous stacking of sats. They had amassed a fortune in Bitcoin and were always on the lookout for new technologies that could help them increase their wealth even further.</p>

      <p>Recently, they had heard about a new technology in the Bitcoin community called Ordinals. The weasels knew that they had to make their appearance in this new arena, and they were determined to make their mark.</p>

      <p>Using the sats they had accumulated over the years, the weasels started to inscribe photos of the most high-ranked weasels in the Den of Iniquity onto the blockchain using the power of Ordinals. This allowed them to create a unique NFT collection that served as a testament to their power and influence...</p>

    </modal>
  </div>
</template>
<script>
import { Card, Button, Modal, FormGroupInput } from '@/components';
import { Popover } from 'element-ui';
import MainFooter from '@/layout/MainFooter';
export default {
  name: 'index-page',
  bodyClass: 'index-page',
  components: {
    Card,
    Modal,
    MainFooter,
    [Popover.name]: Popover,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    setTimeout(() => {
      if(this.$refs.audio.autoplay && this.$refs.audio.muted)
        document.querySelector(".toggle-sound").classList.remove("paused");
    }, 500);
    return {
      story: false
    };
  },
  methods: {
    toggleSound() {
      let audio = this.$refs.audio;
      if (
          (audio.paused || audio.muted) &&
          document.querySelector(".toggle-sound").classList.contains("paused")
      ) {
        audio.play();
        document.querySelector(".toggle-sound").classList.remove("paused");
      } else {
        audio.pause();
        document.querySelector(".toggle-sound").classList.add("paused");
      }
    },
  }
};
</script>
<style lang="scss">
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;
.page-header {
  .content {
    margin-top: 0vh !important;
  }
}
.ws-title {
  font-family: 'thealiens';
  user-select: none;
  color: #e58f74;
  font-size: 3rem;
  line-height: 16rem;
  //text-shadow: 2px 2px 0 #2c2c2c, 2px -2px 0 #2c2c2c, -2px 2px 0 #2c2c2c, -2px -2px 0 #2c2c2c, 2px 0px 0 #2c2c2c, 0px 2px 0 #2c2c2c, -2px 0px 0 #2c2c2c, 0px -2px 0 #2c2c2c;
  text-shadow: -8px 8px #2c2c2c, -6px 6px #2c2c2c, -2px 2px #2c2c2c, -1px 1px #2c2c2c, 0px 0px #2c2c2c, 2px -2px rgba(77,77,77,.6), 4px -4px rgba(77,77,77,.7), 6px -6px rgba(82,82,82,.8), 8px -8px rgba(77,77,77,.9), 10px -10px rgba(77,77,77,1);
}
.card-footer.home {
  a.btn, button.btn {
    color: #2c2c2c;
    border: 2px solid #2c2c2c;
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;
    font-family: 'thealiens';
    &.story {
      color: #e58f74;
      background: #2c2c2c;
      border: 2px solid #e58f74;
      margin-bottom: 15vh;
    }
  }
}
.copyright {
  float: left !important;
  color: #e58f74;
  text-shadow: 0 0 2px #000; /* horizontal-offset vertical-offset 'blur' colour */
  font-size: 1rem;
  font-family: 'thealiens';
  a {
    font-family: 'thealiens';
    color: #e58f74;
    text-shadow: 0 0 2px #000; /* horizontal-offset vertical-offset 'blur' colour */
  }

}
.wsl-modal {
  max-width: 100vw !important;
  margin-top: 0vh !important;
  .modal-content {

    background: #e58f74;
    border: 1rem solid #2c2c2c;
    height: 100vh;
    h4 {
      font-weight: bold;
      font-size: 3rem;
      text-transform: uppercase;
      font-family: 'thealiens';
      color: #2c2c2c;
      text-align: center;
      width: 100%;
    }
    h5 {
      font-weight: bold;
      font-size: 2rem;
      font-family: 'thealiens';
      color: #2c2c2c;
      width: 100%;
    }
    p {
      font-size: 1.3rem;
      font-weight: bold;
      color: #2c2c2c;
      //font-family: 'thealiens';
    }
    .close {
      i {
        color: #2c2c2c;
        font-size: 2rem;
      }
    }
    .modal-body {
      max-height: 100vh;
      height: 100vh;
      overflow: auto;
    }
  }
}
.wsl {
  position: absolute;
  bottom: 0;
  width: 5rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.025);
  }
  &.squiggle {
    //left: -7rem;
    left: 0;
    transform: translateX(-20%);
    z-index: 99;
  }
  &.wiggles {
    left: 0;
    transform: translateX(20%);
    z-index: 98;
  }
  &.whiskers {
    right: 0;
    transform: translateX(-20%);
    z-index: 98;
  }
  &.snickerdoodle {
    right: 0;
    transform: translateX(20%);
    z-index: 99;
  }
}
.wsl-pop {
  h3 {
    font-family: 'thealiens';
    color: #0c0c0c !important;
    font-size: 1.5rem !important;
  }
  .popover-body {
    color: #2c2c2c !important;
  }
}
.music-player {
  position: absolute;
  left: 2rem;
  top: 25%;
  background: #e58f74;
  padding: 1.25rem 1.25rem 1.1rem 1.25rem;
  border-radius: 50%;
}
.toggle-sound {
  i {
    color: #2c2c2c;
    font-size: 2rem;
    background: #e58f74;
  }
  .media-1_button-play {
    display: none;
  }
  &.paused {
    .media-1_button-pause {
      display: none;
    }
    .media-1_button-play {
      display: inline-block;
    }
  }
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: $breakpoint-xs - 1px) {
  // CSS rules for extra small devices
  .wsl {
    width: 5rem;
    &:hover {
      width: 5.5rem;
    }
  }
  .ws-title {
    font-size: 4rem;
    line-height: 6rem;
  }
  .card-footer.home {
    a.btn, button.btn {
      font-size: 1rem;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $breakpoint-xs) {
  // CSS rules for small devices
  .wsl {
    width: 10rem;
    &:hover {
      width: 10.1rem;
    }
  }
  .ws-title {
    font-size: 6rem;
    line-height: 8rem;
  }
  .card-footer.home {
    a.btn, button.btn {
      font-size: 1.2rem;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $breakpoint-sm) {
  // CSS rules for medium devices
  // CSS rules for extra large devices
  .wsl {
    width: 15rem;
    &:hover {
      width: 15.15rem;
    }
  }
  .ws-title {
    font-size: 8rem;
    line-height: 10rem;
  }
  .card-footer.home {
    a.btn, button.btn {
      font-size: 1.4rem;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $breakpoint-md) {
  // CSS rules for large devices
  // CSS rules for extra large devices
  .wsl {
    width: 20rem;
    &:hover {
      width: 20.2rem;
    }
  }
  .ws-title {
    font-size: 10rem;
    line-height: 12rem;
  }
  .card-footer.home {
    a.btn, button.btn {
      font-size: 1.6rem;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $breakpoint-lg) {
  // CSS rules for extra large devices
  .wsl {
    width: 25rem;
    &:hover {
      width: 25.25rem;
    }
  }
  .ws-title {
    font-size: 11rem;
    line-height: 14rem;
  }
  .card-footer.home {
    a.btn, button.btn {
      font-size: 1.8rem;
    }
  }
}

// Extra extra large devices (larger desktops, 1600px and up)
@media (min-width: $breakpoint-xl) {
  // CSS rules for extra extra large devices
  .wsl {
    width: 30rem;
    &:hover {
      width: 30.3rem;
    }
  }
  .ws-title {
    font-size: 13rem;
    line-height: 16rem;
  }
  .card-footer.home {
    a.btn, button.btn {
      font-size: 2rem;
    }
  }
}
</style>
